<template>
  <b-navbar id="navbar" v-bind:fixed-top="true">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="@/assets/logo.png"
          alt="We are the leader in virtual agents"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="div">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="/">Home</a></li>
            <li class="is-active">
              <a href="#" aria-current="page">Solution</a>
            </li>
            <li class="is-active">
              <a href="#" aria-current="page">{{
                activeNav.replace(/^\w/, c => c.toUpperCase())
              }}</a>
            </li>
          </ul>
        </nav>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <span
          class="switch-lang"
          @click="$i18n.locale = 'en'"
          :active="$i18n.locale == 'en'"
          >EN</span
        >
        <span class="gray">/</span>
        <span
          class="switch-lang"
          @click="$i18n.locale = 'th'"
          :active="$i18n.locale == 'th'"
          >TH</span
        >
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navber',
  props: {
    activeNav: {
      type: String,
      default: 'home'
    }
  }
}
</script>
