<template>
  <div>
    <!-- Section About Us -->
    <section id="about-us" class="section white-bg pt-80 pb-80">
      <div class="container">
        <div class="pl-30 mt-30">
          <div class="columns">
            <div class="column is-7">
              <img src="@/assets/logo-color-black-text.png" width="400" />

              <div class="title mt-50">
                True Voice is now
                <span class="text-accentix">Amity Accentix</span>
              </div>
              <div class="desc mt-50">
                We are excited to announce that True Voice Co.,Ltd. is now Amity
                Accentix Co.,Ltd.<br /><br />
                While our name is changing, our dedication to company mission
                remains the same,<br />and our services continue to run as
                usual.
              </div>
              <div class="mt-30">
                <button class="btn-accentix" @click="goToAccentix()">
                  <span>Visit Amity Accentix</span>
                </button>
              </div>
            </div>
            <div class="column is-5">
              <img src="@/assets/amity-voicebot.webp" width="400" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section About Us -->
  </div>
</template>

<script>
import AOS from 'aos'

export default {
  name: 'Home',
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    setTimeout(() => {
      this.slideShow = true
    }, 500)
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    goToAccentix() {
      window.open('https://accentix.ai', '_blank')
    },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    handleScroll(event) {
      const children = this.$el.querySelectorAll('section.section')

      children.forEach(element => {
        const top = element.offsetTop - 52
        const bottom = top + element.scrollHeight
        if (window.scrollY >= top && window.scrollY < bottom)
          this.activeNav = element.id
      })
    }
  }
}
</script>

<style scoped lang="scss">
$bg-color: #f9f9f9;
$accentix-color: #04ac80;

#about-us {
  background-color: $bg-color;
  height: calc(100vh);
}

.title {
  text-align: left;
  font-size: 2.75rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.desc {
  text-align: left;
  font-size: 18px;
}

.text-accentix {
  color: $accentix-color;
}

.btn-accentix {
  font-family: 'Nunito', sans-serif;
  border-radius: 4px;
  background-color: #04ac80;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  padding: 0 25px;
  width: 220px;
  height: 45px;
  line-height: 40px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;

  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  span:after {
    content: url('~@/assets/arrow-white.svg');
    width: 1rem;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  &:hover span {
    padding-right: 25px;
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }
}
</style>
