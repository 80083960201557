<template>
  <div>
    <div v-if="sector == 'banking'">
      <banking />
    </div>
    <div v-else-if="sector == 'telecom'">
      <telecom />
    </div>
    <div v-else-if="sector == 'airline'">
      <airline />
    </div>
    <div v-else-if="sector == 'entertainment'">
      <entertainment />
    </div>
    <div v-else-if="sector == 'medical'">
      <medical />
    </div>
    <div v-else-if="sector == 'insurance'">
      <insurance />
    </div>
    <div v-else>
      <page-404 />
    </div>
  </div>
</template>
<script>
import Banking from './components/Banking'
import Telecom from './components/Telecom'
import Airline from './components/Airline'
import Entertainment from './components/Entertainment'
import Medical from './components/Medical'
import Insurance from './components/Insurance'

import Page404 from '@/views/Page404'

export default {
  name: 'Solutions',
  components: {
    Banking,
    Telecom,
    Airline,
    Entertainment,
    Medical,
    Insurance,
    Page404
  },
  data() {
    return {
      sector: ''
    }
  },
  created() {
    this.sector = this.$route.params.sector
  }
}
</script>
