import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Solutions from '../views/solutions/Solutions'
import Page404 from '../views/Page404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solutions/:sector',
    name: 'Solutions',
    component: Solutions
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404 // 404
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
