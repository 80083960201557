import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App.vue'
import router from './router'

import Buefy from 'buefy'

import AOS from 'aos'

import axios from 'axios'
import VueScrollTo from 'vue-scrollto'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.use(Buefy)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -52
})
Vue.use(VueReCaptcha, { siteKey: '6Lfe-8sZAAAAAMnCANELOKufmyIa1Hogxa5BvuHz' })

new Vue({
  created() {
    AOS.init({ once: true })
  },
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
