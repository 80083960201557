<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import '@/scss/variables.scss'

export default {
  name: 'App',
  data() {
    return {
      isShowToTop: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroy() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    handleScroll(e) {
      this.isShowToTop =
        document.body.scrollTop > 50 || document.documentElement.scrollTop > 50
    },
    backToTop() {
      document.body.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
@import '~bulma';
@import '~buefy/src/scss/buefy';
@import '@/scss/default.scss';
@import '@/scss/custom.scss';

@import '~aos/dist/aos.css';
</style>
