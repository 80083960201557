<template>
  <div>
    <breadcrumb :activeNav="'banking'" />
    <section class="section gray-bg pt-100 pb-80">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="section-title">
              <h6 class="title">
                Our Solutions
              </h6>
              <h4 class="sub-title">Bunch of <span>Services</span></h4>
            </div>
            <p class="text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nec
              est arcu. Maecenas semper tortor. In elementum in risus sed
              commodo. Phasellus nisi ligula, luctus at tempor vitae, placerat
              at ante. Cras sed consequat justo. Curabitur laoreet eu est vel
              convallis.
            </p>
          </div>
          <div class="column">
            <div class="testimonial-right-content">
              <div class="quota">
                <b-icon
                  pack="fas"
                  icon="quote-right"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="testimonial-content-wrapper">
                <b-carousel
                  v-model="carousel"
                  :animated="'slide'"
                  :has-drag="true"
                  :arrow="false"
                  :autoplay="true"
                  :pause-hover="false"
                  :pause-info="false"
                  :pause-info-type="'is-primary'"
                  :interval="10000"
                >
                  <b-carousel-item
                    v-for="(item, index) in carousels"
                    :key="index"
                  >
                    <div class="single-testimonial">
                      <div class="testimonial-text">
                        <p class="text">{{ item.text }}</p>
                      </div>
                      <div
                        class="testimonial-author d-sm-flex justify-content-between"
                      >
                        <div class="author-info d-flex align-items-center">
                          <div>
                            <b-icon
                              pack="fas"
                              :icon="item.icon"
                              size="is-medium"
                              type="is-primary"
                            ></b-icon>
                          </div>
                          <div class="author-name media-body">
                            <h4 class>{{ item.title }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-item>
                </b-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section white-bg pt-60 pb-80">
      <div class="container">
        <div class="hero-content section-title text-center">
          <h4 class="sub-title">B<span>e</span>n<span>e</span>fit</h4>
        </div>
        <div class="columns">
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section gray-bg pt-60 pb-80">
      <div class="container">
        <div class="hero-content section-title text-center">
          <h4 class="sub-title">
            C<span>o</span>re Techn<span>o</span>l<span>o</span>gy
          </h4>
        </div>
        <div class="columns">
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="single-service d-flex">
              <div class="service-icon">
                <b-icon
                  pack="fas"
                  icon="user-friends"
                  size="is-large"
                  type="is-primary"
                ></b-icon>
              </div>
              <div class="service-content media-body">
                <h4 class="service-title">Highly Experienced</h4>
                <p class="text">
                  Lorem Ipsum is simply dummy tex of the printing and
                  typesetting industry. Lorem Ipsum .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Breadcrumb from './Breadcrumb'

export default {
  name: 'Banking',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      carousels: [
        {
          title: 'Consultancy',
          icon: 'headset',
          text:
            'Praesent scelerisque, odio eu fermentum malesuada, nisi arcu volutpat nisl, sit amet convallis nuncturp.',
        },
        {
          title: 'Design',
          icon: 'drafting-compass',
          text:
            'Praesent scelerisque, odio eu fermentum malesuada, nisi arcu volutpat nisl, sit amet convallis nuncturp.',
        },
        {
          title: 'Implementation',
          icon: 'chalkboard-teacher',
          text:
            'Praesent scelerisque, odio eu fermentum malesuada, nisi arcu volutpat nisl, sit amet convallis nuncturp.',
        },
        {
          title: 'Optimization',
          icon: 'tasks',
          text:
            'Praesent scelerisque, odio eu fermentum malesuada, nisi arcu volutpat nisl, sit amet convallis nuncturp.',
        },
        {
          title: 'Maintenance',
          icon: 'cogs',
          text:
            'Praesent scelerisque, odio eu fermentum malesuada, nisi arcu volutpat nisl, sit amet convallis nuncturp.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.single-service {
  padding: 65px 20px 30px;
  position: relative;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.single-service .service-content {
  padding-left: 25px;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
  margin-left: 20px;
}
h4 {
  font-weight: 800;
}

.testimonial-right-content {
  position: relative;
  background-color: #f6f6f6;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.07);
  border-radius: 50px;
  max-width: 500px;
  padding-top: 50px;

  .quota {
    position: absolute;
    top: 10px;
    left: 15px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    opacity: 0.1;
  }

  .testimonial-content-wrapper {
    position: relative;
    left: 70px;
  }
}

.single-testimonial {
  background-color: #fff;
  padding: 40px 30px 50px;
  border-radius: 50px;

  .testimonial-text .text {
    // font-size: 24px;
    // font-weight: 700;
    line-height: 36px;
    padding-bottom: 25px;
  }
  .testimonial-author {
    border-top: 1px solid #dedede4f;
    padding-top: 50px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .single-testimonial {
    border-top: 1px solid #dedede4f;
    padding-top: 50px;
  }
  .testimonial-content-wrapper {
    left: 0px !important;
  }
}
</style>
