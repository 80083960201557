<template>
  <div class="parent">
    <div class="child text-center">
      <div class="text-404">
        <span class="text">4</span>
        <i class="fas fa-meh icon-style fa-spin"></i>
        <span class="text">4</span>
      </div>
      <div class="text-not-found">Page Not Found</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Page404',
}
</script>

<style scoped lang="scss">
.parent {
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);

  .child {
    width: 100%;

    .text-404 .text {
      font-size: 6em;
      font-weight: 800;
      line-height: 0.9em;
    }
    .text-not-found {
      font-size: 1.5em;
      font-weight: bold;
    }

    .icon-style {
      color: $primary;
      font-size: 5em;
      animation-duration: 5s;
    }
  }
}
</style>
